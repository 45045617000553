const initialState = {
  dashboard: [],
  error: null
}

const  dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "dashboard":
      return {
        ...state,
		    dashboard: action.data,
        error: null
      };
    case "dashboard/error":
      return {
      ...state,
      error: action.error
    }
    default:
      return state
  }
}

export default dashboardReducer;