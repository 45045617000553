import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";

import registerEvent from "../_services/google-analytics/register-event.service";


import Loader from "../_components/loader/loader";
import ApiService from "../_services/ApiService";
import Config from "../_config/config";
import "./main.css";
import "./test-player/question-style.css";



const ExamTimeTable = lazy(()=>import("./exam-time-table/ExamTimeTable"));
const InstituteCardDetail = lazy(()=>import("./institute-card-detail/InstituteCardDetail"));
const Notification = lazy(()=>import("./notification/Notification"));
const Attendance = lazy(()=>import("./attendance/Attendance"));
const BiometricAttendance = lazy(()=>import("./biometric-attendance/BiometricAttendance"));
const BiometricAttendanceSemester = lazy(()=>import("./biometric-attendance/BiometricAttendanceSemester"));
const Product = lazy(()=>import("./product/Product"));
const ProductList = lazy(()=>import("./product/ProductList"));
const ProductDetails = lazy(()=>import("./product/ProductDetails"));
const Refer = lazy(()=>import("./refer/Refer"));
const ReferDetails = lazy(()=>import( "./refer/ReferDetails"));
const Learning = lazy(()=>import("./learning/Learning"));
const LearningSteps = lazy(()=>import("./learning/LearningSteps"));
const LearningStepAttachments = lazy(()=>import("./learning/LearningStepAttachments"));
const LearningStepAttachmentDetailsWrapper = lazy(()=>import("./learning/learningStepAttacmentDetails.wrapper"));
const LearningPDFDetails = lazy(()=>import("./learning/LearningPDFDetails"));
const TestTaking = lazy(()=>import("./test/TestTaking"));
const TestSubmit = lazy(()=>import("./test/TestSubmit"));
const TestAnalysis = lazy(()=>import("./test/TestAnalysis"));
const TestResultDetail = lazy(()=>import("./test/TestResultDetail"));
const TestResultQuestion = lazy(()=>import("./test/TestResultQuestion"));
const TestUploadDetails = lazy(()=>import("./test/TestUploadDetails"));
const TestPDFDetails = lazy(()=>import("./test/TestPDFDetails"));
const QuizZone = lazy(()=>import("./quiz/QuizZone"));
const QuizList = lazy(()=>import("./quiz/QuizList"));
const QuizPlayer = lazy(()=>import("./quiz/QuizPlayer"));
const QuizResult = lazy(()=>import("./quiz/QuizResult"));
const LeaderBoard  = lazy(()=>import("./quiz/LeaderBoard"));
const Wallet = lazy(()=>import("./quiz/Wallet"));
const Analysis = lazy(()=>import("./analysis/Analysis"));
const AnalysisList = lazy(()=>import("./analysis/AnalysisList"));
const AnalysisReportDetails = lazy(()=>import("./analysis/AnalysisReportDetails"));
const ExamUpload = lazy(()=>import("./exam-upload/ExamUpload"));
const ExamUploadDetails = lazy(()=>import("./exam-upload/ExamUploadDetails"));
const ExamPDFDetails = lazy(()=>import("./exam-upload/ExamPDFDetails"));
const Resource = lazy(()=>import("./resource/Resource"));
const ResourceDetails = lazy(()=>import("./resource/ResourceDetails"));
const ResourcePDFDetails = lazy(()=>import("./resource/ResourcePDFDetails"));
const Payments = lazy(()=>import("./payments/payments"));
const PaymentDetail = lazy(()=>import("./payments/paymentDetail"));
const MakePayment = lazy(()=>import("./payments/makePayment"));
const PaymentHistory = lazy(()=>import("./payments/paymentHistory"));
const TimeTable = lazy(()=>import("./timetable/TimeTable"));
const DiscussionBoard = lazy(()=>import("./discussion/DiscussionBoard"));
const DiscussionBoardTopicList = lazy(()=>import("./discussion/DiscussionBoardTopicList"));
const AddTopic = lazy(()=>import("./discussion/AddTopic"));
const DiscussionBoardTopic = lazy(()=>import("./discussion/DiscussionBoardTopic"));
const MyPrograms = lazy(()=>import("./my-programs/MyPrograms"));
const MyProgramsList = lazy(()=>import("./my-programs/my-programs-list.component"));
const VideoPlayer = lazy(()=>import("./video-player"));

const Games = lazy(()=>import("./lecode-games/Games"));
const Plays = lazy(()=>import("./lecode-games/Components/Plays"));
const ScratchPad = lazy(()=>import("./scratch-pad"));
const PlayGround = lazy(()=>import("./play-ground/PlayGround"));
const TodayTests = lazy(()=>import("./today-tests/TodayTests"));
const FeedbackForm = lazy(()=>import("./feedback-form/FeedbackForm"));
const XapiMain = lazy(()=>import("./xapi"));
const RecommendationEngine = lazy(()=>import("./recommendation-engine/RecommendationEngine"));
const InstructorFeedback = lazy(()=>import("./feedback-form/InstructorFeedback"));
const Dossier = lazy(()=>import("./dossier/dossier.component"));
const ExamList = lazy(()=>import("./exam-time-table/ExamList"));
const MentorTranscript = lazy(()=>import("./mentor-transcripts/mentor-transcripts.component"));
const Discipline = lazy(()=>import("./discipline/discipline.component"));
const Camera = lazy(()=>import("./camera/camera.component"));
const Convocation = lazy(()=>import("./convocation/convocation.component"));
const chatbot = lazy(()=>import("./chatbot/chatbot"));
const MyShakaPlayer = lazy(()=>import("./shaka/shaka.component"));

const StudentFeedback = lazy(()=>import("./feedback-form/studentfedback.component"));
const ReceiptSeries = lazy(()=>import("./payments/receipt-series.component"));
const DocumentUpload = lazy(()=>import("./document-upload/document-upload.component"));
const SubmitTest = lazy(()=>import("./test-player/components/ViewTestSubmission"));

const DiagnosticTest = lazy(()=>import("./diagnostic-test/diagnostic-test.component"));
const RenderQuestionPaper = lazy(()=>import("./render-question-paper/render-question-paper.component"));
const RegisterCentre =  lazy(()=>import( "./register-centre/register-centre.component"));
const FormBuilder = lazy(()=>import("./form-builder/form-builder.component"));
const Home = lazy(()=>import("./home"));

const taskList = lazy(()=>import("./mentoring/Home"));
const repository = lazy(()=>import("./mentoring/mentorrepository"));
const Chat = lazy(()=>import("./mentoring/chat"));
const mentorprofile = lazy(()=>import("./mentoring/mentorAnalysis"));

const Register = lazy(()=>import("./login/Register"));
const Forgetpwd = lazy(()=>import("./login/Forgetpwd"));
const Forgetusername = lazy(()=>import("./login/Forgetusername"));
const Resetpwd = lazy(()=>import("./login/Resetpwd"));
const Profile = lazy(()=>import("./profile/Profile"));
const ChangePassword = lazy(()=>import("./profile/ChangePassword"));
const Logout = lazy(()=>import("./profile/Logout"));
const Login = lazy(()=>import("./login/Login"));
const AdmitCardAnima = lazy(()=>import('./admit-card-anima/admit-card-anima.component'));
const Dashboard = lazy(()=>import("./dashboard/Dashboard"));
const DetailedProfiling = lazy(()=>import("./detailed-profiling/detailed-profiling.component"));
const VerifyMobile = lazy(()=>import("./verify-mobile/verify-mobile.component"));
const TestDetails = lazy(()=>import("./test/test-details.component"));
const AdmitCard = lazy(()=>import("./admit-card/admit-card.component"));

const TestResult = lazy(()=>import("./test/TestResult"));
const TestInstructions = lazy(()=>import("./test/TestInstructions"));
const TestInstructions2= lazy(()=>import("./test-player/TestInstructions"));
const TestPlayer = lazy(()=>import("./test-player/TestPlayer"));
const TestList = lazy(()=>import("./test/TestList"));
const TestListDetails = lazy(()=>import("./test/TestListDetails"));
const UnsatLanding = lazy(()=>import("./unsat-landing/unsat-landing.component"));
const MessageDetails = lazy(()=>import("./message-details/message-details.component"));
const PaymentInvoice = lazy(()=>import("./payment-invoice/payment-invoice.component"));
const CourseDetails = lazy(()=>import("./course-details/course-details.component"));


const Description = lazy(()=>import("./mentoring/Description"));

//import testResourceDetails from "./resource/TestResourceDetails";
// import LearningStepAttachmentDetails from "./learning/LearningStepAttachmentDetails";

const Main = ({ loginRoute, homeRoute, suppressLogs }) => {
  let history = useHistory();
  
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  console.log(user, "storage");
  
  useEffect(() => {
    const checkUserUpdate = () => {
      console.log("storage1");
      const newUser = JSON.parse(localStorage.getItem("user"));
      if (user?.userId === newUser?.userId) return;
      setUser(newUser);
    }
    checkUserUpdate();
  });

  const sendTrackingData = async (page) => {
    let trackingData = {
      page
    };
    let res = await ApiService.trackPage(trackingData);
    console.log(res, "<-----res");
  }

  useEffect(() => {
    if (suppressLogs === null || suppressLogs === true) return;
    console.log('storage2');
    sendTrackingData('login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const trackPage = async () => {
      const path = location.pathname;
      const pathArray = path.split("/");
      console.log(pathArray, "<--pathArray");
      sendTrackingData(pathArray[1]);
    };
    if (suppressLogs === null || suppressLogs === true) return;
    console.log(suppressLogs, "<---log");
    trackPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppressLogs]);

  useEffect(() => {
    console.log(homeRoute, '<---history');
    if (homeRoute && location.pathname === "/dashboard") {
      // console.log('working');
      history.replace(homeRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeRoute, history]);
  useEffect(() => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    const token = params.get("token");
    if (token) return;
    // console.log(loginRoute,'<---history');
    if (loginRoute && location.pathname === "/") {
      // console.log('working');
      history.replace(loginRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginRoute]);

  const location = useLocation();
  useEffect(() => {
    registerEvent("screen_view", Config.siteTitle, location?.pathname);
  }, [location]);

  return (
    <Suspense fallback={<Loader />}>
    <div className="Main ilearn-main-container">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <Route
          path="/instituteCardDetail"
          exact
          component={InstituteCardDetail}
        />
        <Route path="/mentoring" exact component={taskList} />
        <Route path="/task_description" exact component={taskList} />
        <Route path="/repository" exact component={repository} />
        <Route path="/chat" exact component={Chat} />
        <Route path="/mentoringProfile"exact component={mentorprofile}/>
        
        <Route path="/register" exact component={Register} />
        <Route path="/forgetpwd" exact component={Forgetpwd} />
        <Route path="/forgetusername" exact component={Forgetusername} />
        <Route path="/resetPwd" exact component={Resetpwd} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/changepassword" exact component={ChangePassword} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/notification" exact component={Notification} />
        <Route path="/Products" exact component={Product} />
        <Route path="/Products/:id/:title" exact component={ProductList} />
        <Route path="/ProductDetails/:productId?" exact component={ProductDetails} />
        <Route path="/refer" exact component={Refer} />
        <Route path="/refer/details" exact component={ReferDetails} />
        <Route path="/quizZone" exact component={QuizZone} />
        <Route path="/quizList" exact component={QuizList} />
        <Route path="/quizPlayer" exact component={QuizPlayer} />
        <Route path="/quizResult" exact component={QuizResult} />
        <Route path="/leaderboard" exact component={LeaderBoard} />
        <Route path="/wallet" exact component={Wallet} />
        <Route
          path="/instructorFeedback"
          exact
          component={InstructorFeedback}
        />
        <Route
          path="/studyResource/:typeId/:subTypeId/:type"
          exact
          component={Resource}
        />
        <Route
          path="/studyResource/:typeId/:subTypeId/details/:id"
          exact
          component={ResourceDetails}
        />
        <Route
          path="/studyResource/:typeId/:subTypeId/pdf/:id"
          exact
          component={ResourcePDFDetails}
        />
        <Route path="/attendanceSummary" exact component={Attendance} />
        <Route
          path="/bioMetricAttendance"
          exact
          component={BiometricAttendance}
        />
        <Route
          path="/bioMetricAttendance/semester"
          exact
          component={BiometricAttendanceSemester}
        />
        <Route
          path="/lessonPlan/:typeId/:subTypeId/:title"
          exact
          component={Learning}
        />
        <Route
          path="/lesson/steps/:id/:title"
          exact
          component={LearningSteps}
        />
        <Route
          path="/lesson/steps/attachments/:id/:title"
          exact
          component={LearningStepAttachments}
        />
        <Route
          path="/lesson/steps/attachments/:id/details/:resourceId"
          exact
          component={LearningStepAttachmentDetailsWrapper}
        />
        {/* <Route path="/lesson/steps/attachments/:id/details/:resourceId" exact component={LearningStepAttachmentDetails} /> */}
        <Route
          path="/lesson/steps/attachments/pdf/:id/details/:resourceId"
          exact
          component={LearningPDFDetails}
        />
        <Route path="/TestList" exact component={TestList} />
        <Route path="/TestList/:id" exact component={TestListDetails} />
        <Route
          path="/test-instructions/:pid/:id"
          exact
          component={TestInstructions}
        />
        <Route path="/test-taking/:pid/:id" component={TestTaking} />
        <Route path="/test-submit/:pid/:id" component={TestSubmit} />
        <Route path="/test-analysis/:id" component={TestAnalysis} />
        <Route path="/test-result/:pid/:id" component={TestResult} />
        <Route path="/detailed-profiling/:id" component={DetailedProfiling} />
        <Route path="/test-result-detail/:id" component={TestResultDetail} />
        <Route
          path="/test-result-question-wise/:id"
          component={TestResultQuestion}
        />
        <Route path="/testPerformance" component={Analysis} />
        <Route path="/test/list" component={AnalysisList} />
        <Route path="/test/reports/:id" component={AnalysisReportDetails} />
        <Route path="/test/upload/:id" component={TestUploadDetails} />
        <Route path="/test/pdfview/:id" component={TestPDFDetails} />
        <Route path="/examUpload" component={ExamUpload} />
        <Route path="/exam/upload/:id" component={ExamUploadDetails} />
        <Route path="/exam/pdfview/:id" component={ExamPDFDetails} />
        <Route path="/payment" component={Payments} />
        <Route path="/paymentReceipts" component={ReceiptSeries} />
        <Route path="/paymentDetail" component={PaymentDetail} />
        <Route path="/makePayment" component={MakePayment} />
        <Route path="/paymentHistory" component={PaymentHistory} />
        <Route path="/timetable" component={TimeTable} />
        <Route
          path="/discussionBoard/:id/topics"
          exact
          component={DiscussionBoardTopicList}
        />
        <Route
          path="/discussionBoard/:id/topic/create"
          exact
          component={AddTopic}
        />
        <Route
          path="/discussionBoard/:discussionBoardId/topic/:topicId/comments"
          exact
          component={DiscussionBoardTopic}
        />
        <Route
          path="/discussion/question/:questionId"
          exact
          component={DiscussionBoardTopic}
        />
        <Route
          path="/discussion/resource/:resourceId"
          exact
          component={DiscussionBoardTopic}
        />
        <Route path="/discussionBoard" exact component={DiscussionBoard} />
        <Route
          path="/TestInstructions/:id"
          exact
          component={TestInstructions2}
        />
        <Route path="/TestPlayer/:id" exact component={TestPlayer} />
        <Route path="/MyPrograms" exact component={MyProgramsList} />
        <Route path="/MyPrograms/:enrollmentId" exact component={MyPrograms} />
        <Route path="/videoplayer" exact component={VideoPlayer} />
        <Route path="/gamePlaysInvitations" exact component={Games} />
        <Route path="/gamePlaysAll" exact component={Plays} />
        <Route path="/gamePlaysCompleted" exact component={Games} />
        <Route path="/gamePlaysOpen" exact component={Games} />
        <Route path="/gamePlaysUpcoming" exact component={Games} />
        <Route path="/gamePlaysRegistration" exact component={Games} />
        <Route path="/scratchpad" exact component={ScratchPad} />
        <Route path="/PlayGround" exact component={PlayGround} />
        <Route path="/todayTests" exact component={TodayTests} />
        <Route path="/feedback/form/:id" exact component={FeedbackForm} />
        <Route
          path="/feedback/form/:id/instructor/:instructorId/course/:courseId"
          exact
          component={FeedbackForm}
        />
        <Route
          path="/studentfeedback/form/:id"
          exact
          component={StudentFeedback}
        />
        <Route path="/xapi/v1" exact component={XapiMain} />
        <Route
          path="/recommendation-engine"
          exact
          component={RecommendationEngine}
        />
        <Route path="/examtimetable" component={ExamTimeTable} />
        <Route path="/dossier" component={Dossier} />
        <Route path="/examlist" component={ExamList} />
        <Route path="/mentortranscripts" component={MentorTranscript} />
        <Route path="/discipline" component={Discipline} />
        <Route path="/camera" component={Camera} />
        <Route path="/convocation" component={Convocation} />
        <Route path="/chatbot" component={chatbot} />
        <Route path="/shaka" exact component={MyShakaPlayer} />
        <Route path="/loginwithmobile" exact component={VerifyMobile} />
        <Route path="/testdetails" exact component={TestDetails} />
        <Route path="/admitcard" exact component={AdmitCard} />
        <Route path="/documentUpload" exact component={DocumentUpload} />
        <Route path="/loginwithusername" exact component={Home} />
        <Route path="/view-test-submission/:id" exact component={SubmitTest} />
        <Route path="/diagnostics" exact component={DiagnosticTest} />
        <Route path="/questionpaper/:testId" exact component={RenderQuestionPaper} />
        <Route path="/registercentre/:testId" exact component={RegisterCentre} />
        <Route path="/admitcardanima" exact component={AdmitCardAnima} />
        <Route path="/form/:formId" exact component={FormBuilder} />
        <Route path="/unsatlanding" exact component={UnsatLanding} />
        <Route path="/message-details/:userId" exact component={MessageDetails} />
        <Route path="/paymentInvoice" exact component={PaymentInvoice} />
        <Route path="/courseDetails/:typeId/:subTypeId/:title?" exact component={CourseDetails} />
      </Switch>
    </div>
    </Suspense>
  );
};

export default Main;
