import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./_components/header/header";
import Main from "./pages/main";
import ApiService from "./_services/ApiService";
import googleAnalytics from "./_services/google-analytics/google-analytics.service";
import Config from "./_config/config";
import { AppPropertiesContext } from "./contexts/app-properties.context";
import "./App.css";
import Loader from "./_components/loader/loader";
import SideNavigation from "./_components/side-navigation/side-navigation.component";

function App() {
  const {
    addDataToTests,
    addRegistrationForms,
    addHideStudentDossier,
    addLoginText1,
    addAppProperties
  } = useContext(AppPropertiesContext);
  const [loginRoute, setloginRoute] = useState("");
  const [homeRoute, setHomeRoute] = useState("");
  const [suppressLogs, setSuppressLogs] = useState(null);
  const [loading, setLoading] = useState(true);

  const initGoogleAnalytics = async () => {
    try {
      setLoading(true);
      const res = await ApiService.fetchGoogleAnalytics();
      console.log(res);
      const appProperties =
        typeof res.data === "string" ? JSON.parse(res.data || "{}") : res.data;

      const {
        "GA-Code": id,
        "Home-Route": homeRoute,
        "Login-Route": loginRoute,
        "Home-Title": homeTitle,
        "Suppress-Home-Page-Logs": suppressLogs,
        "Mobile-Login-Footer": loginFooter,
        "Portlet-Url": testCacheUrl,
        "Registration-Forms": registrationForms,
        "Hide-Student-Dossier": hideStudentDossier,
        Tests,
        "Login-Text1": loginText1,
      } = appProperties;

      if (loginText1) {
        addLoginText1(loginText1);
      }

      if (Tests) {
        addDataToTests(Tests);
      }

      if(appProperties){
        addAppProperties(appProperties);
      }

      addHideStudentDossier(hideStudentDossier ? true : false);

      console.log(id, homeRoute);
      console.log(homeTitle, suppressLogs);

      console.log(registrationForms, "form");

      document.title = homeTitle || Config.siteTitle;
      googleAnalytics(id);
      setloginRoute(loginRoute);
      setHomeRoute(homeRoute);
      if (homeRoute) sessionStorage.setItem("homeRoute", homeRoute);
      if (loginRoute) sessionStorage.setItem("loginRoute", loginRoute);
      if (loginFooter) sessionStorage.setItem("loginFooter", loginFooter);
      if (testCacheUrl) sessionStorage.setItem("testCacheUrl", testCacheUrl);
      if (homeTitle) sessionStorage.setItem("homeTitle", homeTitle);
      if (registrationForms) addRegistrationForms(registrationForms);
      setSuppressLogs(suppressLogs);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateFavIcon = () => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    const url = `https://tuningfork-live.s3.ap-southeast-1.amazonaws.com/${Config.siteTitle}/files/logos/favicon.ico`;
    console.log(url);
    link.href = url;
  };

  const updateBodyBackground = () => {
    document.body.style = `background:url(https://tuningfork-live.s3.ap-southeast-1.amazonaws.com/${Config.siteTitle}/files/logos/watermark1.jpg)`;
  };

  useEffect(() => {
    initGoogleAnalytics();
    updateFavIcon();
    updateBodyBackground();
  }, []);

  return (
    <div id="App">
      <div id="page-wrap">
        <div className="container1">
          <Header />
          <Router>
              {loading ? (
                <Loader />
              ) : (
            <SideNavigation>
                <Main
                  loginRoute={loginRoute}
                  homeRoute={homeRoute}
                  suppressLogs={suppressLogs}
                />
            </SideNavigation>
              )}
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
