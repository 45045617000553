import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser } from "@fortawesome/free-solid-svg-icons";

import {
  getUnReadNotificationCount,
  checkNewNotificationsOnServer,
} from "../../push-notification";
import ifacetLogo from "../../_assets/imgs/ifacet-logo.jpeg";

import "./large-header.styles.css";

const LargeHeader = () => {
  useEffect(() => {
    checkNewNotificationsOnServer();
  }, []);

  const history = useHistory();
  const routeChange = (path) => {
    //console.log("Dashboard -> showNotification()... ");
    // let path = "";
    history.push({
      pathname: path,
    });
  };

  const notificationCount = getUnReadNotificationCount();
  return (
    <div className="large-header-container">
      <img className="large-header-logo" src={ifacetLogo} alt="logo" />
      <div className="large-header-icon-container">
        <span
          className="large-header-icon"
          onClick={() => routeChange("/notification")}
        >
          <FontAwesomeIcon icon={faBell} />
          {notificationCount > 0 ? (
            <span class="count">{notificationCount}</span>
          ) : (
            ""
          )}
        </span>

        <span
          className="large-header-icon"
          onClick={() => routeChange("/profile")}
        >
          <FontAwesomeIcon icon={faUser} />
        </span>
      </div>
    </div>
  );
};

export default LargeHeader;
