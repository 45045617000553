import { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import getPortlets from "../../_actions/dashboardActions";
import LargeHeader from "../large-header/large-header.component";
import { AppPropertiesContext } from "../../contexts/app-properties.context";

import "./side-navigation.styles.css";

const SideNavigation = ({ children, dashboard, error, getPortlets }) => {
  const { getUITheme } = useContext(AppPropertiesContext);

  const portlets = dashboard?.data?.portlets || [];
  const history = useHistory();
  const location = useLocation();

  console.log(portlets, "here");


  const naviagateToPortlet = (portlet) => {
    let url = "";

    if (portlet.target === "studyResource" || portlet.target === "lessonPlan" || portlet.target === "courseDetails") {
      url =
        portlet.params.resourceTypeId +
        "/" +
        portlet.params.resourceSubTypeId +
        "/" +
        portlet.name;
    }
    if (
      portlet.name !== "OnlineTests" &&
      portlet.target === "TestList" &&
      typeof portlet.testCategoryIds !== "undefined"
    ) {
      //localStorage.setItem("TLCategory",portlet.testCategoryIds);
      url = portlet.testCategoryIds;
    }

    console.log(url);

    history.push({
      pathname: `/${portlet.target}/${url}`,
      state: { portlet },
    });
  };

  const showSideNavContainer = () => {
    //! do not use this becuase this component won't refresh when this change where as when you use useLocation this component will refresh
    // const pathName = decodeURIComponent(window.location.pathname);
    // console.log(pathName);

    for (let i = 0; i < portlets.length; i++) {
      const currentPortlet = portlets[i];

      const pathType1 = `/${currentPortlet.target}/${currentPortlet.params?.resourceTypeId}/${currentPortlet.params?.resourceSubTypeId}/${currentPortlet.name}`;
      const pathType2 = `/${currentPortlet.target}/${currentPortlet.testCategoryIds}`;
      const pathType3 = `/${currentPortlet.target}/`;
      const pathType4 = `/${currentPortlet.target}`;
      const pathType5 = `/${currentPortlet.target}/${currentPortlet.params?.resourceTypeId}/${currentPortlet.params?.resourceSubTypeId}`;

      if (
        location.pathname === pathType1 ||
        location.pathname === pathType2 ||
        location.pathname === pathType3 ||
        location.pathname === pathType3 ||
        location.pathname === pathType4 ||
        location.pathname === pathType5
      ) {
        return true;
      }
    }

    return false;
  };

  const isActiveTab = (currentPortlet) => {
    const pathType1 = `/${currentPortlet.target}/${currentPortlet.params?.resourceTypeId}/${currentPortlet.params?.resourceSubTypeId}/${currentPortlet.name}`;
    const pathType2 = `/${currentPortlet.target}/${currentPortlet.testCategoryIds}`;
    const pathType3 = `/${currentPortlet.target}/`;
    const pathType4 = `/${currentPortlet.target}`;

    if (
      location.pathname === pathType1 ||
      location.pathname === pathType2 ||
      location.pathname === pathType3 ||
      location.pathname === pathType4
    ) {
      return true;
    }

    return false;
  };

  const checkUITheme = () => {
    if (
      location.pathname === "/" ||
      location.pathname === "/loginwithusername" ||
      location.pathname === "/loginwithmobile" ||
      location.pathname === "/dashboard"
    ) {
      return false;
    }

    if(getUITheme() === "ifacet"){
      return true;
    }

    return false;

  };

  useEffect(() => {
    console.log(checkUITheme() && !portlets.length, "here1")
    if(
      checkUITheme() && !portlets.length
    ){
      console.log("here1");
      getPortlets();
    }
  }, [location]);

  return checkUITheme() ? (
    <>
      <LargeHeader />
      {showSideNavContainer() ? (
        <div className="side-navigation-wrapper">
          <div className="side-nav-container">
            {portlets
              .sort((a, b) => a.id - b.id)
              .map((portlet, i) =>
                portlet.isTile === true ? (
                  <div
                    onClick={() => naviagateToPortlet(portlet)}
                    key={i}
                    className={`side-nav-portlet-container ${
                      isActiveTab(portlet) ? "side-nav-active" : ""
                    }`}
                  >
                    <img
                      className="side-nav-portlet-img"
                      src={portlet.icon?.slice(1)}
                      alt="Messages"
                    />
                    <span className="side-nav-portlet-text">
                      {portlet.name}
                    </span>
                  </div>
                ) : null
              )}
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </>
  ) : (
    children
  );
};

const mapStateToProps = (state) => {
  console.log(state.dashboard.dashboard, "here");
  // localStorage.setItem("portlet", JSON.stringify(state.dashboard?.dashboard?.data));

  return {
    dashboard: state.dashboard.dashboard,
    error: state.dashboard.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPortlets: () => dispatch(getPortlets()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideNavigation);
