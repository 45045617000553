import React, { createContext, useState } from "react";

export const AppPropertiesContext = createContext({
  tests: [],
  addDataToTests: () => {},
  getTestDahboardById: () => {},
  getTestFooter: () => {},
  registrationForms: [],
  addRegistrationForms: ()=>{},
  getCenterAdressById: ()=>{},
  hideStudentDossier: false,
  addHideStudentDossier: ()=>{},
  loginText1: null,
  addLoginText1: ()=>{},
  addAppProperties: ()=>{},
  getUITheme: ()=>{}
});

export const AppPropertiesProvider = ({ children }) => {
  const [tests, setTests] = useState([]);
  const [loginText1, setloginText1] = useState(null);
  const [registrationForms, setRegistrationForms] = useState([]);
  const [hideStudentDossier, setHideStudentDossier] = useState(false);
  const [appProperties, setAppProperties] = useState({});

  const getUITheme = ()=>{
    console.log(appProperties, "heree")
    if(appProperties.uiTheme){
      return appProperties.uiTheme
    }

    return null
  }

  const addAppProperties = (value)=>{
    setAppProperties(value);
  }

  const addLoginText1 = (value)=>{
    setloginText1(value)
  }

  const addHideStudentDossier = (value)=>{
    setHideStudentDossier(value);
  }

  const addDataToTests = (testDetails) => {
    setTests(testDetails);
  };

  const getTestDahboardById = (id) => {
    for (let test of tests) {
      if (test.CategoryId === id) {
        if(test.CumulativeDashboarId || test.CumulativeDashboarId  === 0){
          return test.CumulativeDashboarId;
        }else return null;
      }
    }
    return null;
  };

  const getTestFooter = (id) => {
    console.log(id);
    for (let test of tests) {
      if (test.CategoryId === id) {
        if (test.footer) {
          return test.footer;
        } else return null;
      }
    }
    return null;
  };

  const addRegistrationForms = (formData)=>{
    setRegistrationForms(formData);
  }

  const getCenterAdressById =(formId, centerId)=>{
    console.log(formId, centerId);
    for(let form of  registrationForms){
      if(form["form-id"] === formId){
        for(let center of form.preferredCentres){
          if(center.id === centerId){
            return center.address
          }
        }
      }
    }
    return null;
  }

  const value = {
    tests,
    registrationForms,
    addDataToTests,
    getTestDahboardById,
    getTestFooter,
    addRegistrationForms,
    getCenterAdressById,
    hideStudentDossier,
    addHideStudentDossier,
    loginText1,
    addLoginText1,
    addAppProperties,
    getUITheme
  };

  return (
    <AppPropertiesContext.Provider value={value}>
      {children}
    </AppPropertiesContext.Provider>
  );
};
